<template>
  <div style="height: 100%">
    <div class="pc fzls" id="jjListDiv" v-show="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div class="logo_box">
        <img src="~img/development/61.png" class="logo_img" />
      </div>
      <div class="title">
        <img src="~img/development/59.png" alt="" />
        <p>
          安谋科技前身是Arm公司中国子公司，Arm是一家全球领先的半导体设计与软件平台公司，成立于1990年。<br />
          自2018年安谋科技独立运营以来，立足本土创新，推出“周易”、“星辰”、“山海”及“玲珑”等自研业务产品。
        </p>
      </div>
      <div class="box">
        <img src="~img/development/60.png" class="fz_img bg_img" />
      </div>
      <div class="num">
        <img src="~img/development/012.png" alt="" />
        <el-progress
          :text-inside="true"
          :stroke-width="10"
          :show-text="false"
          :color="color"
          :percentage="parseInt(percentage)"
          v-if="!isNaN(parseInt(percentage))"
          v-on:click.native="forward($event)"
          class="prog"
        ></el-progress>
      </div>
      <div class="hover_box" v-show="showBtnPlay" @click="change">
        <img src="~img/development/011.png" v-if="!play" alt="" />
        <img src="~img/development/010.png" v-if="play" alt="" />
      </div>
    </div>
    <div class="web" v-if="equipment == 'web'">
      <webNav />
      <div class="img-box">
        <img src="~img/development/100.jpg" class="web_bgimg" />
      </div>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
import $ from 'jquery'
export default {
  components: {
    navig,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      h: undefined,
      only: true,
      documentObj: null,
      timer: null,
      imgWidth: 0,
      timer: null,
      play: false,
      percentage: 0,
      timeOut: null,
      color: '#00B3D6',
      showBtnPlay: false,
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    this.h = document.body.clientHeight

    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
  },
  mounted() {
    if (this.h < 920 && this.h > 720) {
      $('.box').height('60%')
    } else if (this.h < 720) {
      $('.box').height('55%')
    } else {
      $('.box').height('65%')
    }
    window.onresize = () => {
      return (() => {
        this.w = document.body.clientWidth
        this.h = document.body.clientHeight
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
        if (this.h < 920 && this.h > 720) {
          $('.box').height('60%')
        } else if (this.h < 720) {
          $('.box').height('55%')
        } else {
          $('.box').height('65%')
        }
      })()
    }
    if (this.equipment == 'pc') {
      this.setScroolFun()
      document.addEventListener('mousemove', this.mousemove, true) // 鼠标移动事件
    }
  },
  methods: {
    // 点击进度条
    forward(event) {
      let div = document.querySelector('.prog') // 获取元素
      let tage = (((event.pageX - 252) / div.offsetWidth) * 100).toFixed(2) // 计算鼠标距离的百分比
      this.percentage = tage // 赋值进度条
      let width = $('.bg_img').width() - this.w // 获取滚动宽度  减去 可视范围宽度
      // console.log(width * (tage / 100).toFixed(2));
      $('#jjListDiv').animate({ scrollLeft: width * (tage / 100).toFixed(2) }, 1000) // 总宽度乘以 需要滚动的百分比 得到实际滚动的距离
    },
    change() {
      // 点击图片的时候获取图片宽度
      this.imgWidth = $('.bg_img').width()
      if (this.documentObj.scrollLeft + 10 >= this.imgWidth - this.w) {
        this.time = setInterval(() => {
          this.documentObj.scrollLeft -= 100
          this.Accounted()
          if (this.documentObj.scrollLeft <= 0) {
            clearInterval(this.time)
          }
        })
      }
      if (this.play) {
        this.play = false
        clearInterval(this.timer)
      } else {
        this.play = true
        this.timer = setInterval(() => {
          // console.log(this.documentObj.scrollLeft + 10, this.imgWidth - this.w);
          if (this.documentObj.scrollLeft - 155 >= this.imgWidth - this.w) {
            console.log('清除定时器')
            clearInterval(this.timer)
            this.percentage = 100
            // 播放到头从新播放
            // clearTimeout(this.timeOut);
            // this.timeOut = setTimeout(() => {
            //   this.documentObj.scrollLeft = 0;
            //   this.play = false;
            //   this.change();
            // }, 1000);
          }
          this.documentObj.scrollLeft += 1
          this.Accounted()
        }, 10)
      }
    },
    // 时间轴
    Accounted() {
      // 总宽
      let totalW = this.documentObj.scrollWidth || this.documentObj.scrollWidth
      // 可视宽
      let clientW = window.innerWidth || document.documentElement.clientWidth
      // 计算有效宽
      let validW = totalW - clientW
      let scrollW = this.documentObj.scrollLeft || this.documentObj.scrollLeft
      let result = ((scrollW / validW) * 100).toFixed(2)
      this.percentage = result
    },
    // 鼠标滚轮控制页面左右滚动
    setScroolFun() {
      //绑定的容器
      this.documentObj = document.getElementById('jjListDiv') // 获取DOM元素节点
      // 添加监听事件（不同浏览器，事件方法不一样，所以可以作判断，也可以如下偷懒）
      this.documentObj.addEventListener('DOMMouseScroll', this.handlerMouserScroll, false) //滚轮事件
      this.documentObj.addEventListener('mousewheel', this.handlerMouserScroll, false) //滚轮事件
    },
    // 左右滚动的计算方式
    handlerMouserScroll(event) {
      //获取滚轮跨距，兼容获取方式
      let detail = event.wheelDelta || event.detail || event.wheelDeltaY
      /*反向*/
      let moveForwardStep = -1
      /*正向*/
      let moveBackStep = 1
      let step = 0
      //如果跨步大于0，表明正向跨步，将跨步放大100倍，改变滑动速度，如果跨步小于0，表明反向跨步，将跨步放大500倍，改变滑动速度
      step = detail > 0 ? moveForwardStep * 80 : moveBackStep * 80
      /*覆盖当前滚动条的位置,单位是像素，叠增或剃减*/
      this.documentObj.scrollLeft = this.documentObj.scrollLeft + step

      //平滑值(越小越慢，不能小于等于0)
      let slipNum = 0.5
      //末尾值（越小，则越平稳，越大越仓促）
      let endNum = 0.1
      /*递减步伐值*/
      let decreasingPaceNum = step
      /*速度*/
      let paceNum = 50

      /*效果一*/
      let t = setInterval(() => {
        if (Math.abs(decreasingPaceNum) < endNum) {
          clearInterval(t)
          return
        }
        decreasingPaceNum = decreasingPaceNum * slipNum
        this.documentObj.scrollLeft = this.documentObj.scrollLeft + decreasingPaceNum
        this.Accounted()
      }, paceNum)
    },
    // 鼠标移入
    mousemove(e) {
      let top = $('.fz_img').offset().top
      let left = $('.fz_img').offset().left
      let windowH = $(window).height()
      let bottom = windowH - top + 150
      if (e.pageY > top && e.pageY < bottom && e.pageX > left) {
        $('body').css('cursor', 'none')
        this.showBtnPlay = true
        $('.hover_box').css('top', e.pageY - 53.5)
        $('.hover_box').css('left', e.pageX - 53.5)
      } else {
        this.showBtnPlay = false
        $('body').css('cursor', 'default')
      }
    },
  },
  beforeDestroy() {
    if (!this.documentObj) return
    this.documentObj.removeEventListener('DOMMouseScroll', this.handlerMouserScroll)
    this.documentObj.removeEventListener('mousewheel', this.handlerMouserScroll)
    document.removeEventListener('mousemove', this.mousemove, true)
  },
}
</script>

<style lang="less" scoped>
.fzls::-webkit-scrollbar {
  display: none;
}
.fzls {
  width: auto;
  height: 100%;
  background-color: #000;
  padding-left: 212px;
  overflow-x: scroll;
  overflow-y: hidden;
  // position: relative;
  .pc_navigtion {
    left: 0;
  }
  .title {
    display: flex;
    top: 160px;
    align-items: center;
    margin-bottom: 2%;
    position: absolute;
    z-index: 20;
    padding-right: 3rem /* 40/100 */;
    img {
      width: 0.04rem /* 4/100 */;
      height: 0.5rem /* 40/100 */;
      margin-right: 27px;
    }
    p {
      font-size: 0.18rem;
      font-family: Microsoft Microsoft YaHei;
      font-weight: 400;
      color: #dddddd;
      line-height: 0.3rem /* 25/100 */;
    }
  }
  @media screen and (max-width: 1282px) {
    .title {
      img {
        height: 0.7rem;
      }
    }
  }
  .box {
    position: relative;
    width: auto;
    height: 65%;
    margin-top: 240px;
    .fz_img {
      height: 100%;
      width: auto;
      position: absolute;
    }
  }
  .num {
    width: 500px;
    height: 23px;
    position: fixed;
    bottom: 3%;
    // left: 100px;
    left: 212px;
    background-color: #f3f3f3;
    // position: relative;
    img {
      width: 14px;
      height: 15px;
      position: absolute;
      top: 50%;
      left: 17px;
      transform: translate(0, -50%);
    }
    .el-progress {
      width: 85%;
      position: absolute;
      top: 50%;
      left: 41px;
      transform: translate(0, -50%);
      cursor: pointer;
      /deep/.el-progress-bar__outer {
        border-radius: 0;
        .el-progress-bar__inner {
          border-radius: 0;
        }
      }
      /deep/.el-progress-bar__outer {
        background-color: #c7c6c6;
      }
    }
    span {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 0.4rem /* 40/100 */;
      font-size: 0.18rem /* 18/100 */;
      font-weight: bold;
    }
    .el-icon-caret-right {
      font-size: 0.3rem /* 18/100 */;
      position: absolute;
      top: 50%;
      right: 0.05rem /* 5/100 */;
      transform: translate(0, -50%);
      cursor: pointer;
    }
  }
  .logo_box {
    width: 212px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #000;
    z-index: 9;
  }
  .logo_img {
    width: 37px;
    position: absolute;
    left: 104px;
    top: 250px;
  }
  .hover_box {
    // pointer-events: none;
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
  }
}
.web {
  background: #000;
  .img-box {
    padding-top: 40px;
  }
  .foot {
    position: relative;
    padding-bottom: 10px;
    background-color: #000;
  }
}
</style>
