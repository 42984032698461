<template>
  <div class="unsubscribe">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/ecology/17.png" class="bg-img" />
      <img src="~img/ecology/15.png" class="logo" />
      <main>
        <img src="~img/ecology/18.png" class="zb-img" />
        <div class="box">
          <p class="p1">您的邮箱</p>
          <el-input v-model="input" placeholder=""></el-input>
          <p class="p2">提交后，您将退订安谋科技的营销邮件通知</p>
          <button @click="cancel">提交</button>
        </div>
      </main>
      <div class="dialog" v-show="showDialog">
        <p class="er">提示：{{ errorTextl }}</p>
        <button @click="showDialog = !showDialog">确定</button>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/ecology/16.png" class="logo" />
      <main>
        <img src="~img/ecology/19.png" class="web-img" />
        <div class="web-box">
          <p class="p1">您的邮箱</p>
          <el-input v-model="input" placeholder=""></el-input>
          <p class="p2">提交后，您将退订安谋科技的营销邮件通知</p>
          <button @click="cancel">提交</button>
        </div>
      </main>
      <div class="dialog" v-show="showDialog">
        <p class="er">提示：{{ errorTextl }}</p>
        <button @click="showDialog = !showDialog">确定</button>
      </div>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
      input: '',
      showDialog: false,
      errorTextl: '',
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    document.title = '生态伙伴计划'
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
  methods: {
    async cancel() {
      const res = await this.axios.post('/v1/api/change_plan', {
        status: 2,
        email: this.input,
      })
      console.log(res)
      this.errorTextl = res.msg
      this.showDialog = true
      if (res.code == '00') {
        this.input = ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
.unsubscribe {
  .pc {
    background: #000;
    position: relative;
    .bg-img {
      width: 100%;
      top: 0;
      left: 0;
    }
    .logo {
      width: 0.38rem;
      position: absolute;
      right: 0.75rem;
      top: 2.84rem;
    }
    main {
      width: 10.25rem;
      height: 5.04rem;
      position: absolute;
      top: 2.51rem;
      left: 50%;
      transform: translate(-50%);
      .zb-img {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .box {
        width: 5.57rem;
        height: 100%;
        margin: auto;
        padding-top: 1.03rem;
        position: relative;
        .p1 {
          color: #fff;
          font-size: 0.2rem;
          margin-bottom: 0.26rem;
        }
        /deep/.el-input__inner {
          background-color: #2b2b2b;
          border: 1px solid #5a5a5a;
          color: #fff;
        }
        .p2 {
          font-size: 0.2rem;
          color: #8f8f8f;
          margin-top: 0.27rem;
          margin-bottom: 0.55rem;
        }
        button {
          width: 2.93rem;
          height: 0.59rem;
          background: #00b3d6;
          font-size: 0.2rem;
          color: #f8f8f8;
          border-radius: 0.1rem;
          display: block;
          margin: auto;
        }
      }
    }
    .dialog {
      width: 4.82rem;
      height: 2.07rem;
      background: #f8f8f8;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 0.1rem;
      padding-top: 0.64rem;
      z-index: 99;
      p {
        font-size: 0.2rem;
        text-align: center;
      }
      button {
        width: 2.93rem;
        height: 0.59rem;
        background: #00b3d6;
        border-radius: 0.1rem;
        display: block;
        margin: auto;
        margin-top: 0.4rem;
        color: #fff;
        font-size: 0.2rem;
        cursor: pointer;
      }
    }
  }
  .web {
    background: #000;
    position: relative;
    .logo {
      width: 133px;
      position: absolute;
      right: 24px;
      top: 87px;
    }
    main {
      position: relative;
      .web-img {
        width: 100%;
      }
      .web-box {
        width: 100%;
        position: absolute;
        top: 152px;
        padding-left: 20px;
        padding-right: 20px;
        .p1 {
          font-size: 16px;
          margin-bottom: 10px;
          color: #fff;
        }
        /deep/.el-input__inner {
          background-color: #2b2b2b;
          border: 1px solid #5a5a5a;
          border-radius: 10px;
        }
        .p2 {
          color: #8f8f8f;
          font-size: 12px;
          margin-top: 10px;
          margin-bottom: 40px;
        }
        button {
          width: 100%;
          background: #00b3d6;
          color: #f8f8f8;
          height: 40px;
          border-radius: 10px;
          font-size: 14px;
        }
      }
    }
    .dialog {
      width: 281px;
      height: 124px;
      background: #f8f8f8;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      padding-top: 36px;
      z-index: 99;
      p {
        font-size: 12px;
        text-align: center;
      }
      button {
        width: 147px;
        height: 30px;
        background: #00b3d6;
        border-radius: 10px;
        display: block;
        margin: auto;
        margin-top: 30px;
        color: #fff;
        font-size: 12px;
      }
    }
    .foot {
      position: relative;
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>
