<template>
  <div>
    <el-input v-model="companyName" placeholder="公司名称"></el-input>
    <el-input v-model="applicantName" placeholder="申请人姓名"></el-input>
    <el-input v-model="companyWebsite" placeholder="公司网站"></el-input>
    <el-input v-model="applicantPosition" placeholder="申请职位"></el-input>
    <el-input v-model="address" placeholder="地址"></el-input>
    <el-input v-model="email" placeholder="电子邮件"></el-input>
    <el-input v-model="phone" placeholder="电话号码"></el-input>
    <el-input v-model="status" placeholder="是否同意"></el-input>
    <ul>
      <li v-for="(item,index) in list" :key="item.questionId">
        <p>{{item.questionName}}</p>
        <el-checkbox-group v-model="checked[index]" @change='changeCheck(index)'>
          <el-checkbox v-for="check in item.options" :key="check.optionId" :label=check.optionId>{{check.optionTxt}}
          </el-checkbox>
        </el-checkbox-group>
      </li>
    </ul>
    <el-upload class="upload-demo" :show-file-list='false' :auto-upload="false" :on-change="handleChange"
      :before-upload='beforeUpload' drag action="#" multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
    <ul>
      <li v-for="(val,index) in fileList" :key="val.uid">
        {{val.name}} <i @click="remove(index)" class="el-icon-error"></i>
      </li>
    </ul>
    <el-button type="success" icon="el-icon-check" circle @click="submint"></el-button>
  </div>
</template>


<script>
export default {
  data() {
    return {
      list: [],
      checked: {},
      questionOPtions: [],
      fileList: [],
      companyName: "XXX", //公司名称
      applicantName: "XXX", // 申请人姓名
      companyWebsite: "XXX.com", // 公司网站
      applicantPosition: "XXX", // 申请职位
      address: "XX.XX.XX", // 地址
      email: "XXXX@.com", // 电子邮件
      phone: "XXXXXXXXXX", // 电话号码
      status: "1", // 是否同意
    };
  },
  created() {
    this.getFromData();
  },
  methods: {
    beforeUpload(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList);
    },
    async getFromData() {
      const res = await this.axios.get("/v1/api/get_plan");
      if (res.code == "00") {
        this.list = res.data;
        this.list.forEach((item, index) => {
          this.$set(this.checked, index, []);
        });
        console.log(this.list);
      }
    },
    changeCheck(index) {
      this.list[index].checkedList = this.checked[index];
    },
    async submint() {
      this.setoptions();
      let fd = new FormData();
      fd.append("questionOPtions", JSON.stringify(this.questionOPtions));
      fd.append("companyName", this.companyName);
      fd.append("applicantName", this.applicantName);
      fd.append("companyWebsite", this.companyWebsite);
      fd.append("applicantPosition", this.applicantPosition);
      fd.append("address", this.address);
      fd.append("email", this.email);
      fd.append("phone", this.phone);
      fd.append("status", this.status);

      this.fileList.forEach((item) => {
        fd.append("files", item.raw);
      });
      const res = await this.axios.post("/v1/api//submit_plan", fd, {
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      console.log(res);
    },
    // 整合选项数据
    setoptions() {
      this.questionOPtions = [];
      this.list.forEach((item) => {
        console.log(item);
        item.optionsl = [];
        item.checkedList.forEach((val) => {
          item.optionsl.push({
            optionId: val,
          });
        });
        this.questionOPtions.push({
          questionId: item.questionId,
          options: item.optionsl,
        });
      });
      console.log(this.questionOPtions);
    },
    remove(index) {
      this.fileList.splice(index, 1);
    },
  },
};
</script>