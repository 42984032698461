<template>
  <div class="cooperateInfo">
    <div class='pc' v-if='equipment === "pc"'>
      <navig :w="w" :only="only" :mask="mask" />
      <main>
        <img :src="img" alt="">
        <div class='text_box'>
          <div class='richtext'>
            <h5>{{title}}</h5>
            <div class='content' v-html='content'></div>
            <div class='pageTurning clearfix'>
              <span @click='perup'>上一篇</span>
              <span>|</span>
              <span @click='next'>下一篇</span>
              <span @click='$router.push({path:"/cooperate"})'>返回列表</span>
            </div>
          </div>
          <div class='relevant_content' v-if='label.length > 0 || likeText.length > 0'>
            <img src="~img/ecology/67.png" alt="">
            <div class='icon_box'>
              <div class='icon' v-for='(item,index) in label' :key='index'>{{item}}</div>
            </div>
            <p class='p1' v-show='likeText.length > 0'>相关内容</p>
            <ul>
              <li v-for='(item,index) in likeText' :key='index'>
                <div class='fenge'></div>
                <a :href="item.likeLink" target='_blank'>{{item.likeText}}</a>
              </li>
            </ul>
          </div>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if='equipment === "web"'>
      <web-nav :mask="mask" />
      <main>
        <img :src="imgOriginal" alt="">
        <h5>{{title}}</h5>
        <div class='content' v-html='content'></div>
        <div class='webpageTurning'>
          <span @click='perup'>上一篇</span>
          <span>|</span>
          <span @click='next'>下一篇</span>
          <span @click='$router.push({path:"/cooperate"})'>返回列表</span>
        </div>
        <div class='relevant_content' v-if='label.length > 0 || likeText.length > 0'>
          <img src="~img/ecology/67.png" alt="">
          <div class='icon_box' v-if='label.length > 0'>
            <div class='icon' v-for='(item,index) in label' :key='index'>{{item}}</div>
          </div>
          <p class='p1' v-show='likeText.length > 0'>相关内容</p>
          <ul>
            <li v-for='(item,index) in likeText' :key='index'>
              <div class='fenge'></div>
              <a :href="item.likeLink" target='_blank'>{{item.likeText}}</a>
            </li>
          </ul>
        </div>
      </main>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      equipment: "",
      only: true,
      mask: "NO",
      showFooter: true,
      id: '',
      img: '',
      imgOriginal: '',
      content: '',
      likeText: [],
      title: '',
      preId: '',
      nextId: '',
      label: []
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    this.id = this.getQueryValue('id')
    console.log(this.id)
  },
  mounted() {
    this.getCooperateInfo()
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  watch: {
    $route(to, form) {
      this.id = to.query.id
      this.getCooperateInfo()
    },
  },
  methods: {
    async getCooperateInfo() {
      this.likeText = []
      const res = await this.axios.get(`/v1/api/get_cases_detail?casesId=${this.id}`)
      if (res.code === '00') {
        console.log(res)
        this.img = this.URL + res.data.imgBanner
        this.imgOriginal = this.URL + res.data.imgOriginal
        this.content = res.data.content
        this.title = res.data.title
        this.nextId = res.data.nextId
        this.preId = res.data.preId
        this.label = res.data.label
        document.title = this.title
        res.data.likeText.forEach(item => {
          this.likeText.push(JSON.parse(item))
        })
      }
    },
    next() {
      if (this.nextId) {
        this.$router.push({ path: `/cooperateInfo?id=${this.nextId}` })
      } else {
        this.$toast('当前已是最后一篇')
      }
    },
    perup() {
      if (this.preId) {
        this.$router.push({ path: `/cooperateInfo?id=${this.preId}` })
      } else {
        this.$toast('当前已是第一篇')
      }
    }
  }
}
</script>


<style lang="less" scoped>
.cooperateInfo {
  background: #ededed;
  min-height: 100vh;
  .pc {
    main {
      margin-left: 0.8rem;
      margin-right: 1.39rem;
      padding-top: 2.25rem;
      .text_box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 0.97rem;
      }
      .richtext {
        width: 12.14rem;
        h5 {
          font-size: 0.5rem;
          line-height: 0.7rem;
          color: #000;
          margin-bottom: 0.8rem;
        }
        .content {
          font-size: 0.22rem;
          color: #3e3e3e;
          line-height: 0.46rem;
          margin-bottom: 1.19rem;
          /deep/img {
            max-width: 100%;
          }
          /deep/ul {
            padding-left: 0.65rem;
            li,
            ol {
              list-style-type: disc;
              // list-style-position: inside;
            }
          }
        }
        .pageTurning {
          float: right;
          display: flex;
          width: 3.27rem;
          justify-content: space-between;
          font-size: 0.22rem;
          color: #808080;
          margin-bottom: 0.75rem;
          span {
            cursor: pointer;
          }
        }
      }
      .relevant_content {
        width: 3.67rem;
        border: solid 1px #cbcbcb;
        border-top: none;
        img {
          margin-bottom: 0.24rem;
        }
        .icon_box {
          padding-right: 0.06rem;
          padding-left: 0.2rem;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.2rem;
          .icon {
            min-height: 0.42rem;
            padding: 0.1rem 0.2rem;
            margin-bottom: 10px;
            background: #00b3d6;
            font-size: 0.2rem;
            color: #fff;
            margin-right: 0.14rem;
          }
        }
        .p1 {
          font-size: 0.24rem;
          font-weight: bold;
          color: #000;
          padding-left: 0.2rem;
        }
        ul {
          padding-top: 0.2rem;
          li {
            padding-left: 0.2rem;
            display: flex;
            margin-bottom: 0.29rem;
            padding-right: 0.2rem;
            .fenge {
              width: 0.14rem;
              height: 2px;
              background: #00b3d6;
              margin-right: 0.05rem;
              margin-top: 0.15rem;
            }
            a {
              font-size: 0.2rem;
              color: #5f5f5f;
            }
          }
          li:hover {
            a {
              text-decoration: underline;
              color: #00b3d6;
            }
          }
        }
      }
    }
  }
  .web {
    position: relative;
    main {
      width: 86.5%;
      margin: auto;
      padding-top: 78px;
      overflow: hidden;
      h5 {
        font-size: 18px;
        line-height: 28px;
        color: #000;
        margin-top: 22px;
        margin-bottom: 27px;
      }
      .content {
        font-size: 13px;
        color: #3e3e3e;
        line-height: 23px;
        /deep/img {
          max-width: 100%;
        }
        /deep/ul {
          padding-left: 44px;
          li,
          ol {
            list-style-type: disc;
            // list-style-position: inside;
          }
        }
      }
      .webpageTurning {
        width: 164px;
        margin: auto;
        font-size: 11px;
        display: flex;
        justify-content: space-between;
        color: #808080;
        margin-top: 36px;
      }
      .relevant_content {
        border: solid 1px #cbcbcb;
        border-top: none;
        margin-top: 34px;
        img {
          margin-bottom: 12px;
        }
        .icon_box {
          padding-right: 10px;
          padding-left: 10px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 7px;

          .icon {
            height: 21px;
            padding: 0 13px;
            background: #00b3d6;
            line-height: 21px;
            font-size: 10px;
            color: #fff;
            margin-right: 7px;
            margin-bottom: 10px;
          }
        }
        .p1 {
          font-size: 12px;
          font-weight: bold;
          color: #000;
          padding-left: 10px;
        }
        ul {
          padding-top: 10px;
          li {
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            margin-bottom: 14px;
            .fenge {
              width: 7px;
              height: 1px;
              background: #00b3d6;
              margin-right: 5px;
              margin-top: 6px;
            }
            a {
              font-size: 10px;
              color: #5f5f5f;
            }
          }
          li:hover {
            a {
              text-decoration: underline;
              color: #00b3d6;
            }
          }
        }
      }
    }
    .foot {
      background: #000;
    }
  }
}
</style>