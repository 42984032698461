<template>
  <div class="ecology">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div class="header">
        <img src="~img/ecology/01.png" class="bg-img" />
        <img src="~img/ecology/02.png" class="logo-img" />
        <h5>生态伙伴计划</h5>
        <div class="heng"></div>
        <p class="title">
          安谋科技依托于成熟的Arm技术生态与自研业务产品矩阵，通过战略合作、产品技术支持、协作项目、联合营销等形式与生态伙伴共建上下游产业生态，共同推动各领域的软硬件、解决方案、工具链、行业标准以及社区联盟等生态环节发展，促进中国智能计算生态的创新和繁荣。
        </p>
        <div class="content">
          <h6>聚焦领域</h6>
          <ul>
            <li>
              <img src="~img/ecology/03.png" alt="" />
              <p>产品技术生态</p>
            </li>
            <li>
              <img src="~img/ecology/06.png" alt="" />
              <p>产业场景</p>
            </li>
            <li>
              <img src="~img/ecology/04.png" alt="" />
              <p>标准&联盟</p>
            </li>
            <li>
              <img src="~img/ecology/05.png" alt="" />
              <p>社区&人才</p>
            </li>
          </ul>
        </div>
        <p class="lianjie">
          更多安谋科技生态伙伴计划信息，请咨询：<a href="mailto:eco-partner@armchina.com" target="_blank">eco-partner@armchina.com</a>
        </p>
        <div class="joinas">
          <h5>加入生态伙伴计划</h5>
          <div class="fege"></div>
          <h6>欢迎加入安谋科技生态伙伴计划，请<span @click="$router.push({ path: '/applyjoin' })">点击此链接</span>填写申请表</h6>
        </div>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <div class="header">
        <img src="~img/ecology/29.png" class="bg-img" />
        <img src="~img/ecology/07.png" class="logo-img" />
        <h5>生态伙伴计划</h5>
        <div class="heng"></div>
        <p class="title">
          安谋科技依托于成熟的Arm技术生态与自研业务产品矩阵，通过战略合作、产品技术支持、协作项目、联合营销等形式与生态伙伴共建上下游产业生态，共同推动各领域的软硬件、解决方案、工具链、行业标准以及社区联盟等生态环节发展，促进中国智能计算生态的创新和繁荣。
        </p>
        <div class="content">
          <h6>聚焦领域</h6>
          <ul>
            <li>
              <img src="~img/ecology/03.png" alt="" />
              <p>产品技术生态</p>
            </li>
            <li>
              <img src="~img/ecology/06.png" alt="" />
              <p>产业场景</p>
            </li>
            <li>
              <img src="~img/ecology/04.png" alt="" />
              <p>标准&联盟</p>
            </li>
            <li>
              <img src="~img/ecology/05.png" alt="" />
              <p>社区&人才</p>
            </li>
          </ul>
        </div>
        <p class="lianjie">
          更多安谋科技生态伙伴计划信息<br />请咨询：<a href="mailto:eco-partner@armchina.com" target="_blank">eco-partner@armchina.com</a>
        </p>
        <h5>生态伙伴计划</h5>
        <div class="heng"></div>
        <p class="title">欢迎加入安谋科技生态伙伴计划，请<span @click="$router.push({ path: '/applyjoin' })">点击此链接</span>填写申请表</p>
        <div class="foot">
          <web-footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
    document.title = '生态伙伴计划'
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
}
</script>

<style lang="less" scoped>
.ecology {
  min-height: 100vh;
  .pc {
    background-image: linear-gradient(#fff, #000 10%);
    .header {
      background-color: rgba(0, 0, 0, 0.6);
      min-height: 7.08rem;
      padding-top: 3.23rem;
      .bg-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-img {
        position: absolute;
        right: 0.75rem;
        top: 2.86rem;
        width: 0.38rem;
      }
      .heng {
        width: 1.66rem;
        height: 0.03rem;
        background: #00b3d6;
        margin-top: 0.24rem;
        margin-bottom: 0.5rem;
        margin-left: 1.35rem;
      }
      h5 {
        font-size: 0.4rem;
        color: #fff;
        font-weight: bold;
        font-family: Microsoft YaHe;
        margin-left: 1.35rem;
      }
      .title {
        font-size: 0.2rem;
        color: #fff;
        line-height: 0.38rem;
        font-family: Microsoft YaHe;
        margin-left: 1.35rem;
        margin-right: 1.47rem;
      }
      .content {
        h6 {
          font-size: 0.36rem;
          color: #fff;
          text-align: center;
          margin-top: 0.8rem;
        }
        ul {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          display: flex;
          margin-top: 0.8rem;
          li {
            width: 25%;
            img {
              width: 1.29rem;
              height: 1.48rem;
              margin: auto;
            }
            p {
              text-align: center;
              color: #a1a1a1;
              font-size: 0.2rem;
              margin-top: 0.19rem;
            }
          }
        }
      }
      .lianjie {
        margin-left: 1.35rem;
        margin-top: 1.4rem;
        font-size: 0.2rem;
        padding-bottom: 1.13rem;
        color: #fff;
        a {
          color: #00b4d4;
          // text-decoration: underline;
          // border-bottom: width style color;
          border-bottom: 1px solid #00b4d4;
        }
      }
      .joinas {
        .fege {
          width: 1.16rem;
          height: 0.03rem;
          margin-left: 1.35rem;
          margin-top: 0.24rem;
          background: #00b3d6;
        }
        h6 {
          font-size: 0.2rem;
          color: #fff;
          margin-left: 1.35rem;
          margin-top: 0.47rem;
          margin-bottom: 0.78rem;
          span {
            color: #00b3d6;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  .web {
    background-image: linear-gradient(#fff, #000 15%);
    position: relative;

    .header {
      background: rgba(0, 0, 0, 0.9);
      padding-top: 150px;
      .logo-img {
        width: 60%;
        float: right;
        margin-right: 20px;
      }
      .bg-img {
        position: absolute;
        top: 0;
        left: 0;
      }
      h5 {
        font-size: 20px;
        color: #fff;
        margin-top: 60px;
        margin-left: 40px;
      }
      .heng {
        width: 100px;
        height: 2px;
        background: #00b3d6;
        margin-left: 40px;
        margin-top: 10px;
      }
      .title {
        font-size: 12px;
        color: #fff;
        margin-left: 40px;
        width: 80%;
        margin-top: 20px;
        span {
          color: #00b3d6;
          text-decoration: underline;
        }
      }
      .content {
        padding-top: 40px;
        h6 {
          font-size: 20px;
          color: #fff;
          text-align: center;
          margin-bottom: 25px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          margin: auto;
          li {
            width: 50%;
            margin-bottom: 30px;
            img {
              width: 50%;
              margin: auto;
            }
            p {
              text-align: center;
              color: #a1a1a1;
              margin-top: 10px;
            }
          }
        }
      }
      .lianjie {
        color: #fff;
        font-size: 12px;
        margin-left: 40px;
        margin-top: 50px;
        a {
          border-bottom: 1px solid #00b4d4;
          font-size: 13px;
        }
      }
      .foot {
        position: relative;
        padding-bottom: 10px;
        background-color: #000;
      }
    }
  }
}
</style>
