<template>
  <div class="ecology">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <div class="header">
        <img src="~img/ecology/30.png" class="bg-img" />
        <img src="~img/ecology/31.png" class="logo-img" />
        <div class="tit">
          <h5>开发平台计划</h5>
          <div class="right-title" @click="community">
            至极术社区了解详情
            <img src="~img/ecology/57.png" alt="" />
          </div>
        </div>
        <div class="heng"></div>
        <p class="title">
          安谋科技开发平台计划深耕开发板和云平台，基于自研业务产品和Arm技术方案，覆盖智能汽车、基础设施、消费终端和智能物联网等多个领域，探索技术合作，推广技术场景，展现应用案例。安谋科技将联合客户与合作伙伴，共同推进技术、产品和方案的应用落地，打造开发者平台，建设开发者生态。
        </p>
        <div class="content">
          <h6>聚焦领域</h6>
          <ul>
            <li>
              <img src="~img/ecology/32.png" alt="" />
              <p>智能汽车</p>
            </li>
            <li>
              <img src="~img/ecology/33.png" alt="" />
              <p>基础设施</p>
            </li>
            <li>
              <img src="~img/ecology/34.png" alt="" />
              <p>消费终端</p>
            </li>
            <li>
              <img src="~img/ecology/35.png" alt="" />
              <p>智能物联网</p>
            </li>
          </ul>
        </div>
        <p class="lianjie">
          更多安谋科技开发平台计划信息，请咨询：<a href="mailto:dev-platform@armchina.com" target="_blank">dev-platform@armchina.com</a>
        </p>
        <!-- <div class="joinas">
          <h5>加入生态伙伴计划</h5>
          <div class="fege"></div>
          <h6>欢迎加入安谋科技生态伙伴计划，请<span @click="$router.push({path:'/applyjoin'})">点击此链接</span>填写申请表</h6>
        </div> -->
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <div class="header">
        <img src="~img/ecology/69.png" class="bg-img" />
        <img src="~img/ecology/68.png" class="logo-img" />
        <div class="tit">
          <h5>开发平台计划</h5>
          <div class="right-title" @click="community">
            至极术社区了解详情
            <img src="~img/ecology/57.png" alt="" />
          </div>
        </div>
        <div class="heng"></div>
        <p class="title">
          安谋科技开发平台计划深耕开发板和云平台，基于自研业务产品和Arm技术方案，覆盖智能汽车、基础设施、消费终端和智能物联网等多个领域，探索技术合作，推广技术场景，展现应用案例。安谋科技将联合客户与合作伙伴，共同推进技术、产品和方案的应用落地，打造开发者平台，建设开发者生态。
        </p>
        <div class="content">
          <h6>聚焦领域</h6>
          <ul>
            <li>
              <img src="~img/ecology/70.png" alt="" />
              <p>智能汽车</p>
            </li>
            <li>
              <img src="~img/ecology/71.png" alt="" />
              <p>基础设施</p>
            </li>
            <li>
              <img src="~img/ecology/72.png" alt="" />
              <p>消费终端</p>
            </li>
            <li>
              <img src="~img/ecology/73.png" alt="" />
              <p>智能物联网</p>
            </li>
          </ul>
        </div>
        <p class="lianjie">
          更多安谋科技开发平台计划信息，请咨询：<br /><a href="mailto:dev-platform@armchina.com" target="_blank">dev-platform@armchina.com</a>
        </p>
        <!-- <h5>生态伙伴计划</h5>
        <div class="heng"></div>
        <p class="title">欢迎加入安谋科技生态伙伴计划，请<span @click="$router.push({path:'/applyjoin'})">点击此链接</span>填写申请表</p> -->
        <div class="foot">
          <web-footer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navig from '@/components/navigation.vue' // pc 导航
import pcFooter from '@/components/pcFooter.vue' // pc 底部
import webNav from '@/components/webNav.vue' // web 导航
import webFooter from '@/assembly/web/webFooter.vue' // web 底部
import up from '@/components/Backtotop.vue'
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: '',
      equipment: '',
      only: true,
      showFooter: true,
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth
    if (this.w <= 750) {
      this.equipment = 'web'
    } else {
      this.equipment = 'pc'
    }
  },
  mounted() {
    document.title = '安谋科技-开发平台计划'
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth
        if (this.w <= 750) {
          this.equipment = 'web'
        } else {
          this.equipment = 'pc'
        }
      })()
    }
  },
  methods: {
    community() {
      window.open('https://aijishu.com/')
    },
  },
}
</script>

<style lang="less" scoped>
.ecology {
  min-height: 100vh;
  .pc {
    background-image: linear-gradient(#fff, #000 10%);
    .header {
      background-color: rgba(0, 0, 0, 0.6);
      min-height: 7.08rem;
      padding-top: 3.23rem;
      .bg-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-img {
        position: absolute;
        right: 0.75rem;
        top: 2.86rem;
        width: 0.38rem;
      }
      .heng {
        width: 1.66rem;
        height: 0.03rem;
        background: #00b3d6;
        margin-top: 0.24rem;
        margin-bottom: 0.5rem;
        margin-left: 1.35rem;
      }
      .tit {
        display: flex;
        align-items: center;
        h5 {
          font-size: 0.4rem;
          color: #fff;
          font-weight: bold;
          font-family: Microsoft YaHe;
          margin-left: 1.35rem;
          margin-right: 0.22rem;
        }
        .right-title {
          width: 2.48rem;
          height: 0.54rem;
          background: url('../../assets/img/ecology/56.png') no-repeat;
          background-size: contain;
          font-size: 0.2rem;
          font-weight: bold;
          color: #f8f8f8;
          line-height: 0.54rem;
          position: relative;
          padding-left: 0.25rem;
          cursor: pointer;
          img {
            width: 0.14rem;
            position: absolute;
            right: 0.2rem;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }

      .title {
        font-size: 0.2rem;
        color: #fff;
        line-height: 0.38rem;
        font-family: Microsoft YaHe;
        margin-left: 1.35rem;
        margin-right: 1.47rem;
      }
      .content {
        margin-bottom: 1.9rem;
        h6 {
          font-size: 0.36rem;
          color: #fff;
          text-align: center;
          margin-top: 0.8rem;
        }
        ul {
          padding-left: 2.5rem;
          padding-right: 2.5rem;
          display: flex;
          margin-top: 0.8rem;
          li {
            width: 25%;
            img {
              width: 1.29rem;
              height: 1.48rem;
              margin: auto;
            }
            p {
              text-align: center;
              color: #a1a1a1;
              font-size: 0.2rem;
              margin-top: 0.19rem;
            }
          }
        }
      }
      .lianjie {
        margin-left: 1.35rem;
        margin-top: 1.4rem;
        font-size: 0.2rem;
        padding-bottom: 1.13rem;
        color: #fff;
        a {
          color: #00b4d4;
          // text-decoration: underline;
          // border-bottom: width style color;
          border-bottom: 1px solid #00b4d4;
        }
      }
      .joinas {
        .fege {
          width: 1.16rem;
          height: 0.03rem;
          margin-left: 1.35rem;
          margin-top: 0.24rem;
          background: #00b3d6;
        }
        h6 {
          font-size: 0.2rem;
          color: #fff;
          margin-left: 1.35rem;
          margin-top: 0.47rem;
          margin-bottom: 0.78rem;
          span {
            color: #00b3d6;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  .web {
    background-image: linear-gradient(#fff, #000 15%);
    position: relative;
    width: 100%;
    overflow: hidden;
    .header {
      background: rgba(0, 0, 0, 0.9);
      padding-top: 150px;
      .logo-img {
        width: 65.5%;
        float: right;
        margin-right: 20px;
        position: relative;
      }
      .bg-img {
        position: absolute;
        top: 0;
        left: 0;
      }
      .tit {
        margin-top: 60px;
        display: flex;
        width: 100%;
        margin-left: 40px;
        position: relative;
        align-items: center;
        h5 {
          font-size: 19px;
          color: #fff;
          margin-right: 10px;
        }
        .right-title {
          // width: 140px;
          height: 26px;
          background: url('../../assets/img/ecology/56.png') no-repeat;
          background-size: 100% 100%;
          font-size: 11px;
          font-weight: bold;
          color: #f8f8f8;
          line-height: 26px;
          position: relative;
          padding-left: 9px;
          display: flex;
          align-items: center;
          padding-right: 10px;
          img {
            width: 7px;
            margin-left: 5px;
            // position: absolute;
            // right: 9px;
            // top: 50%;
            // transform: translate(0, -50%);
          }
        }
      }

      .heng {
        width: 100px;
        height: 2px;
        background: #00b3d6;
        margin-left: 40px;
        margin-top: 10px;
      }
      .title {
        font-size: 12px;
        color: #fff;
        margin-left: 40px;
        width: 80%;
        margin-top: 20px;
        position: relative;
        span {
          color: #00b3d6;
          text-decoration: underline;
        }
      }
      .content {
        padding-top: 40px;
        h6 {
          font-size: 20px;
          color: #fff;
          text-align: center;
          margin-bottom: 25px;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          margin: auto;
          li {
            width: 50%;
            margin-bottom: 30px;
            img {
              width: 50%;
              margin: auto;
            }
            p {
              text-align: center;
              color: #a1a1a1;
              margin-top: 10px;
            }
          }
        }
      }
      .lianjie {
        color: #fff;
        font-size: 12px;
        margin-left: 40px;
        margin-top: 50px;
        a {
          border-bottom: 1px solid #00b4d4;
          font-size: 13px;
        }
      }
      .foot {
        position: relative;
        padding-bottom: 10px;
        background-color: #000;
      }
    }
  }
}
</style>
