<template>
  <div class="cooperate">
    <div class="pc" v-if='equipment === "pc"'>
      <navig :w="w" :only="only" />
      <div class="header">
        <img src="~img/ecology/58.png" class="bg-img">
        <img src="~img/ecology/59.png" class="logo-img">
        <h5>优秀合作案例</h5>
        <div class="heng"></div>
      </div>
      <ul>
        <li @click='cooperateInfo(item.casesId)' v-for='item in list' :key='item.casesId'>
          <div class="img_box">
            <img :src="item.imgOriginal" alt="">
          </div>
          <div class="text_box">
            <img src="~img/ecology/65.png" alt="">
            <p class='p1'>{{item.title}}</p>
            <div class='icon-time'>
              <div class='icon'>
                <div class='one-txt-cut' v-for='(val,index) in item.label' :key='index'>{{val}}</div>
              </div>
              <p class='p2'>{{item.showDate}}</p>
            </div>
          </div>
        </li>
        <!-- <li>
          <div class="img_box">
            <img src="~img/ecology/62.png" alt="">
          </div>
          <div class="text_box">
            <img src="~img/ecology/65.png" alt="">
            <p class='p1'>安谋科技“周易”NPU加持，芯擎科技“龍鷹一号”智能座舱芯片量产发布</p>
            <div class='icon-time'>
              <div class='icon'>
                <span>智能汽车</span>
                <span>AI</span>
              </div>
              <p class='p2'>2023-03-30</p>
            </div>
          </div>
        </li>
        <li>
          <div class="img_box">
            <img src="~img/ecology/63.png" alt="">
          </div>
          <div class="text_box">
            <img src="~img/ecology/65.png" alt="">
            <p class='p1'>安谋科技“周易”NPU加持，芯擎科技“龍鷹一号”智能座舱芯片量产发布</p>
            <div class='icon-time'>
              <div class='icon'>
                <span>智能汽车</span>
                <span>AI</span>
              </div>
              <p class='p2'>2023-03-30</p>
            </div>
          </div>
        </li> -->
      </ul>
      <div class="fenye">
        <el-pagination :page-size="pageSize" :pager-count="11" layout="prev, pager, next,jumper" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class='web' v-if='equipment === "web"'>
      <web-nav />
      <img src="~img/ecology/74.png" class="web_top_img" />
      <img src="~img/ecology/75.png" class="logo_img" />
      <div class='title_box'>
        <h5>优秀合作案例</h5>
        <div class="fenge"></div>
      </div>
      <ul>
        <li v-for='item in list' :key='item.casesId' @click='cooperateInfo(item.casesId)'>
          <img :src="item.imgOriginal" alt="">
          <div class='content'>
            <div class="li_fenge"></div>
            <p class='p1'>{{item.title}}</p>
            <div class='icon-time'>
              <div class='icon'>
                <div class='one-txt-cut' v-for='(val,index) in item.label' :key='index'>{{val}}</div>
              </div>
              <p class='p2'>{{item.showDate}}</p>
            </div>
          </div>
        </li>
        <!-- <li>
          <img src="~img/ecology/62.png" alt="">
          <div class='content'>
            <div class="li_fenge"></div>
            <p class='p1'>安谋科技“周易”NPU加持，芯擎科技</p>
            <div class='icon-time'>
              <div class='icon'>
                <span>智能汽车</span>
                <span>AI</span>
              </div>
              <p class='p2'>2023-1-20</p>
            </div>
          </div>
        </li>
        <li>
          <img src="~img/ecology/62.png" alt="">
          <div class='content'>
            <div class="li_fenge"></div>
            <p class='p1'>安谋科技“周易”NPU加持，芯擎科技</p>
            <div class='icon-time'>
              <div class='icon'>
                <span>智能汽车</span>
                <span>AI</span>
              </div>
              <p class='p2'>2023-1-20</p>
            </div>
          </div>
        </li> -->
      </ul>
      <div class='more' @click='moreList'>点击加载</div>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>




<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: undefined,
      only: true,
      showFooter: true,
      equipment: '',
      pageNum: 1,
      pageSize: 6,
      list: [],
      total: 0
    }
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
  },
  mounted() {
    this.getList()
    document.title = '安谋科技-优秀合作案例'
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        this.h = document.documentElement.clientHeight; // 视口的高度
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    cooperateInfo(id) {
      // this.$router.push({ path: `/cooperateInfo?id=${id}` })
      let routeData = this.$router.resolve({
        name: 'cooperateInfo',
        query: { cylType: this.$route.query.cylType },
        params: { id: id }
      })
      console.log(routeData.href + `?id=${id}`)
      let url = routeData.href + `?id=${id}`
      window.open(url, '_blank')

      // window.open(url, '_blank');
    },
    async getList() {
      const res = await this.axios.get(`/v1/api/get_cases?pageNum=${this.pageNum}&pageSize=${this.pageSize}`)
      console.log(res)
      if (res.code === '00') {
        if (this.equipment === 'pc') {
          this.list = res.data.rows
          this.list.forEach(item => {
            item.imgOriginal = this.URL + item.imgOriginal
            if (item.label.length > 4) {
              item.label = item.label.slice(0, 3)
            }
          })
        } else {
          res.data.rows.forEach(item => {
            item.imgOriginal = this.URL + item.imgOriginal
            this.list.push(item)
          })
          this.list.forEach(item => {
            if (item.label.length > 3) {
              item.label = item.label.slice(0, 2)
            }
          })

        }
        console.log(this.list)

        this.total = res.data.total

      }
    },
    handleCurrentChange(val) {
      console.log(val)
      this.pageNum = val
      this.getList()
      document.documentElement.scrollTop = 0;
    },
    moreList() {
      if (this.list.length < this.total) {
        this.pageNum++
        this.getList()
      } else {
        this.$toast('没有更多了')
      }
    }
  }
}
</script>



<style lang="less" scoped>
.cooperate {
  .pc {
    background-image: linear-gradient(#fff, #000 10%);
    .header {
      background-color: rgba(0, 0, 0, 0.6);
      padding-top: 3.23rem;
      .bg-img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .logo-img {
        position: absolute;
        right: 0.75rem;
        top: 2.86rem;
        width: 0.38rem;
      }
      .heng {
        width: 1.66rem;
        height: 0.03rem;
        background: #00b3d6;
        margin-top: 0.24rem;
        margin-bottom: 0.5rem;
        margin-left: 1.35rem;
      }
      h5 {
        font-size: 0.4rem;
        color: #fff;
        font-weight: bold;
        font-family: Microsoft YaHe;
        margin-left: 1.35rem;
        margin-right: 0.22rem;
      }
    }
    ul {
      padding-left: 1.37rem;
      padding-right: 1.46rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 0.3rem;
      grid-row-gap: 1rem;
      position: relative;
      margin-bottom: 1.37rem;
      li {
        width: 5.27rem;
        height: 3.8rem;
        cursor: pointer;
        .img_box {
          height: 2.24rem;
          overflow: hidden;
          img {
            width: 100%;
            transition: 0.5s all;
          }
        }
        .text_box {
          overflow: hidden;
          height: 1.56rem;
          border: 1px solid #2f2e2e;
          border-top: none;
          padding-top: 0.21rem;
          padding-left: 0.24rem;
          padding-right: 0.24rem;
          img {
            width: 0.81rem;
          }
          .p1 {
            font-size: 0.18rem;
            color: #fff;
            line-height: 0.3rem;
            margin-top: 0.12rem;
            margin-bottom: 0.15rem;
            min-height: 0.6rem;
          }
          .icon-time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon {
              display: flex;
              div {
                max-width: 1.2rem;
                padding: 0.05rem 0.16rem;
                color: #fff;
                background: #00b3d6;
                border-radius: 0.03rem;
                margin-right: 0.1rem;
              }
            }
            .p2 {
              font-size: 0.14rem;
              color: #fff;
              font-family: Arial;
            }
          }
        }
      }
      li:hover {
        .img_box {
          img {
            transform: scale(1.1);
          }
        }
        .p1 {
          color: #00b3d6;
        }
      }
    }
    .fenye {
      position: relative;
      height: 160px;
      .el-pagination {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        color: #ffffff;
        background: #000;
        /deep/.el-pagination__editor {
          padding: 0 5px;
          input {
            color: #000;
            font-weight: 600;
          }
        }
        /deep/.el-pagination__jump {
          color: #fff;
        }
        /deep/.btn-next {
          background-color: #000;
          i {
            color: #fff;
          }
        }
        /deep/.btn-prev {
          background-color: #000;
          i {
            color: #fff;
          }
        }
        /deep/.el-pager {
          li {
            background: #424242;
            margin-left: 5px;
          }
          li.active {
            color: #e7e7e7;
            background: #848484;
            min-width: 30px;
          }
        }
      }
    }
  }
  .web {
    position: relative;
    background: #000;
    min-height: 100vh;
    .web_top_img {
      position: absolute;
      left: 0;
      top: 0;
    }
    .logo_img {
      width: 210px;
      position: absolute;
      right: 22px;
      top: 147px;
    }
    .title_box {
      width: 86.5%;
      margin: auto;
      padding-top: 210px;
      position: relative;
      h5 {
        font-size: 21px;
        color: #fff;
      }
      .fenge {
        width: 60px;
        height: 2px;
        background: #00b3d6;
        margin-top: 15px;
        margin-bottom: 24px;
      }
    }
    ul {
      width: 86.5%;
      margin: auto;
      position: relative;
      li {
        img {
          height: 139px;
        }
        .content {
          width: 100%;
          min-height: 105px;
          background: url("../../assets/img/ecology/76.png") no-repeat;
          background-size: 99.5% 100%;
          padding-top: 12px;
          padding-left: 15px;
          padding-right: 15px;
          margin-bottom: 32px;
          .li_fenge {
            width: 50px;
            height: 2px;
            background: #00b3d6;
            margin-bottom: 10px;
          }
          .p1 {
            font-size: 14px;
            color: #fff;
            line-height: 20px;
            min-height: 36px;
            margin-bottom: 5px;
          }
          .icon-time {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon {
              display: flex;
              div {
                font-size: 10px;
                line-height: 18px;
                background: #00b3d6;
                padding: 3px 10px;
                color: #fff;
                border-radius: 2px;
                margin-right: 5px;
                max-width: 80px;
              }
            }
            .p2 {
              font-size: 10px;
              color: #fff;
            }
          }
        }
      }
    }
    .more {
      line-height: 70px;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      color: #9d9d9d;
      text-align: center;
    }
  }
}
</style>