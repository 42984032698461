<template>
  <div class="ResourceLibrary">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <main>
        <div class='doclist' v-for='item in list' :key='item.materialId'>
          <p class='p1'>{{item.materialName}}</p>
          <ul>
            <li v-for='val in item.datas' :key='val.dataId'>
              <a :href='val.url'>
                <div class="imgBox" :style="'background:url(' + val.materialPic + ') no-repeat;background-size: 100% 100%;background-position: center;'">
                  <div class='mengban'></div>
                </div>
                <div class="btn">
                  <p class='p2'>{{val.dataName}}</p>
                  <!-- <a :href='val.url' class="down">
                  <img src="~img/ResourceLibrary/03.png" alt="">
                </a> -->
                  <div class="down">
                    <img src="~img/ResourceLibrary/03.png" alt="">
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <img src="~img/ResourceLibrary/07.png" class='logo'>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <main>
        <img src="~img/ResourceLibrary/04.png" class='logo'>
        <div class="doclist" v-for='item in list' :key='item.materialId'>
          <p class='p1'>{{item.materialName}}</p>
          <ul>
            <li v-for='val in item.datas' :key='val.dataId'>
              <a :href='val.url'>
                <div class='imgBOx' :style="'background:url(' + val.materialPic + ') no-repeat;background-size: 100% 100%;background-position: center;'">
                </div>
                <div class="btn">
                  <p class='p2'>{{val.dataName}}</p>
                  <div class="down">
                    <img src="~img/ResourceLibrary/03.png" alt="">
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </main>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true,
      list: []
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    document.title = "资源下载";
    this.getlist();
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    async getlist() {
      const res = await this.axios.get("/v1/api/get_material");
      console.log(res);
      if (res.code === "00") {
        this.list = res.data;
      }
    },
    async download(url) {
      window.open(url);
    }
  }
};
</script>

<style lang="less" scoped>
.ResourceLibrary {
  .pc {
    background: #000;
    main {
      min-height: 14.2rem;
      background: url("~img/ResourceLibrary/01.png") no-repeat;
      background-size: contain;
      padding-top: 2.5rem;
      .logo {
        width: 39px;
        position: absolute;
        top: 327px;
        right: 75px;
      }
      .doclist {
        padding-left: 1.34rem;
        margin-bottom: 0.12rem;
        .p1 {
          display: inline-block;
          font-size: 0.4rem;
          font-weight: bold;
          color: #fff;
          border-bottom: 0.03rem solid #0fb2d2;
          line-height: 1.1rem;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          padding-top: 0.53rem;
          li {
            width: 5rem;
            margin-right: 0.73rem;
            margin-bottom: 0.73rem;
            cursor: pointer;
            .imgBox {
              width: 100%;
              height: 2.81rem;
              border-bottom: 1px solid #fff;
              // background: url("~img/ResourceLibrary/02.png") no-repeat;
              background-size: 100% 100%;
              background-position: center;
              margin-bottom: 21px;
              transition: 0.5s all;
              .mengban {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
                transition: 0.5s all;
              }
            }
            .btn {
              display: flex;
              justify-content: space-between;
              .p2 {
                font-size: 0.2rem;
                color: #fff;
                line-height: 0.58rem;
                font-weight: 400;
                transition: 0.2s all;
              }
              .down {
                width: 0.58rem;
                height: 0.58rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgba(245, 235, 225, 0.2);
                cursor: pointer;
                transition: 0.2s all;
                img {
                  width: 0.26rem;
                  height: 0.23rem;
                }
              }
            }
          }
          li:hover {
            .imgBox {
              border-color: #0fb2d2;
              background-size: 120% 120%;
              .mengban {
                background: rgba(0, 0, 0, 0);
              }
            }
            .down {
              background: #0fb2d2;
            }
            .p2 {
              color: #0fb2d2;
            }
          }
        }
      }
    }
  }
  .web {
    background: rgba(0, 0, 0, 1);
    main {
      min-height: 402px;
      background: url("~img/ResourceLibrary/05.png") no-repeat;
      background-size: contain;
      padding-top: 165px;

      .logo {
        width: 213px;
        position: absolute;
        top: 87px;
        right: 24px;
      }
      .doclist {
        padding-left: 24px;
        margin-bottom: 60px;
        .p1 {
          display: inline-block;
          font-size: 25px;
          font-weight: bold;
          color: #fff;
          line-height: 60px;
          border-bottom: 2px solid #0fb2d2;
          margin-bottom: 35px;
        }
        ul {
          li {
            width: 92%;
            margin-bottom: 54px;
            .imgBOx {
              height: 183px;
              background: url("~img/ResourceLibrary/06.png") no-repeat;
              background-size: 100% 100%;
              background-position: center;
              margin-bottom: 14px;
            }
            .btn {
              display: flex;
              justify-content: space-between;
              .p2 {
                font-size: 16px;
                color: #0fb2d2;
                font-weight: 400;
                line-height: 38px;
              }
              .down {
                width: 38px;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #0fb2d2;
                img {
                  width: 17px;
                }
              }
            }
          }
        }
      }
    }
    .foot {
      padding-bottom: 10px;
      position: relative;
      background-color: #000;
    }
  }
}
</style>