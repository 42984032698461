<template>
  <div class="agreement">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <main>
        <img src="~img/ecology/11.png" class="logo">
        <h4>安谋科技生态伙伴计划协议</h4>
        <div class="heng"></div>
        <ul>
          <li>
            <p>这些条款和条件( “协议” )由注册地址位于深圳市前海深港合作区前湾一路1号A栋201室(入驻深圳市前海商务秘书有限公司)的安谋科技(中国)有限公司( “安谋科技” )与同意
              这些条款和条件并经安谋科技接受加入生态伙伴计划的实体( “生态伙伴” )共同签订。安谋科技或生态伙伴单独称为 “一方”，合称为“双方”。</p>
          </li>
          <li>
            <p>如果您代表生态伙伴接受本协议，则生态伙伴声明并保证：(i)您拥有使生态伙伴受本协议约束的全部合法授权；(ii)生态伙伴已阅读并理解本协议；并且(iii)您代表生态伙伴同意本
              协议。如果您没有约束生态伙伴的合法授权，请不要点击接受。</p>
          </li>
          <li>
            <p>您接受本协议并不意味着安谋科技有义务接受您加入生态伙伴计划( “计划” )。本协议生效日期为以下两个日期中较晚者：(i)您点击接受之日；和(ii)安谋科技接受您加入计划之日。</p>
          </li>
          <li>
            <p>一、生态伙伴计划 <br> 1.1安谋科技生态伙伴计划是由安谋科技发起的一项计划， 旨在为生态伙伴业务提供多维助力， 创造核芯价值。安谋科技将依托于成熟的Arm技术生态与自研业务产品矩阵， 通过战略合
              作、产品技术支持、协作项目、联合营销等形式与生态伙伴共建上下游产业生态，共同推动各领域的软硬件、解决方案、工具链、行业标准以及社区联盟等生态环节发展，促进中国智 能计算生态的创新和繁荣。</p>
          </li>
          <li>
            <p>1.2生态伙伴依据本计划享有的权益及义务将由安谋科技不时地向生态伙伴发布。</p>
          </li>
          <li>
            <p>二、宣传 <br> 生态伙伴特此向安谋科技授予一项免费的、非排他的、全球性的、可撤销的许可，以允许安谋科技及其子公司和分支机构在如下情形中使用生态伙伴的公司名称、产品名称、公司标识
              和产品标识：在安谋科技官网(www.armchina.com)及相关自媒体资源(包括但不限于公众号/微博/视频号等)中，用于“生态伙伴计划”相关宣传材料。</p>
          </li>
          <li>
            <p>三、信息交换<br>根据本协议，双方可交换公开信息。 安谋科技和生态伙伴之间交换任何保密信息前，双方应签署保密协议。双方签订的保密协议将适用于根据本协议提供的或与本协议相关的任何保密信息。</p>
          </li>
          <li>
            <p>四、期限<br>本协议自生效日期开始的一(1)年内(“初始期限”)有效。此后，除非任一方在当前期限结束前三十(30)天内书面通知另一方终止本协议，否则本协议将自动延续一(1)年
              (每1年均称为“续订期限”)，依此类推。</p>
          </li>
          <li>
            <p>
              五、一般条款<br>5.1未经安谋科技事先书面同意，生态伙伴不得全部或部分转让或以其他方式转让本协议或其在本协议下的任何权利和义务。<br>5.2安谋科技和生态伙伴是独立的缔约方，本协议的任何内容不得被解释为在双方之间建立了合伙、合资、代理或其他本协议目的以外的法律关系。<br>5.3本协议的有效性、解释和履行应受中国法律管辖。
            </p>
          </li>
        </ul>

      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <main>
        <img src="~img/ecology/12.png" class="logo">
        <h4>安谋科技 <br> 生态伙伴计划协议</h4>
        <div class="heng"></div>
        <ul>
          <li>
            <p>这些条款和条件( “协议” )由注册地址位于深圳市前海深港合作区前湾一路1号A栋201室(入驻深圳市前海商务秘书有限公司)的安谋科技(中国)有限公司( “安谋科技” )与同意
              这些条款和条件并经安谋科技接受加入生态伙伴计划的实体( “生态伙伴” )共同签订。安谋科技或生态伙伴单独称为 “一方”，合称为“双方”。</p>
          </li>
          <li>
            <p>如果您代表生态伙伴接受本协议，则生态伙伴声明并保证：(i)您拥有使生态伙伴受本协议约束的全部合法授权；(ii)生态伙伴已阅读并理解本协议；并且(iii)您代表生态伙伴同意本
              协议。如果您没有约束生态伙伴的合法授权，请不要点击接受。</p>
          </li>
          <li>
            <p>您接受本协议并不意味着安谋科技有义务接受您加入生态伙伴计划( “计划” )。本协议生效日期为以下两个日期中较晚者：(i)您点击接受之日；和(ii)安谋科技接受您加入计划之日。</p>
          </li>
          <li>
            <p>一、生态伙伴计划 <br> 1.1安谋科技生态伙伴计划是由安谋科技发起的一项计划， 旨在为生态伙伴业务提供多维助力， 创造核芯价值。安谋科技将依托于成熟的Arm技术生态与自研业务产品矩阵， 通过战略合
              作、产品技术支持、协作项目、联合营销等形式与生态伙伴共建上下游产业生态，共同推动各领域的软硬件、解决方案、工具链、行业标准以及社区联盟等生态环节发展，促进中国智 能计算生态的创新和繁荣。</p>
          </li>
          <li>
            <p>1.2生态伙伴依据本计划享有的权益及义务将由安谋科技不时地向生态伙伴发布。</p>
          </li>
          <li>
            <p>二、宣传 <br> 生态伙伴特此向安谋科技授予一项免费的、非排他的、全球性的、可撤销的许可，以允许安谋科技及其子公司和分支机构在如下情形中使用生态伙伴的公司名称、产品名称、公司标识
              和产品标识：在安谋科技官网(www.armchina.com)及相关自媒体资源(包括但不限于公众号/微博/视频号等)中，用于“生态伙伴计划”相关宣传材料。</p>
          </li>
          <li>
            <p>三、信息交换<br>根据本协议，双方可交换公开信息。 安谋科技和生态伙伴之间交换任何保密信息前，双方应签署保密协议。双方签订的保密协议将适用于根据本协议提供的或与本协议相关的任何保密信息。</p>
          </li>
          <li>
            <p>四、期限<br>本协议自生效日期开始的一(1)年内(“初始期限”)有效。此后，除非任一方在当前期限结束前三十(30)天内书面通知另一方终止本协议，否则本协议将自动延续一(1)年
              (每1年均称为“续订期限”)，依此类推。</p>
          </li>
          <li>
            <p>
              五、一般条款<br>5.1未经安谋科技事先书面同意，生态伙伴不得全部或部分转让或以其他方式转让本协议或其在本协议下的任何权利和义务。<br>5.2安谋科技和生态伙伴是独立的缔约方，本协议的任何内容不得被解释为在双方之间建立了合伙、合资、代理或其他本协议目的以外的法律关系。<br>5.3本协议的有效性、解释和履行应受中国法律管辖。
            </p>
          </li>
        </ul>
      </main>
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>



<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";
export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true,
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    document.title = "生态伙伴计划";
  },
  mounted() {
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
};
</script>





<style lang="less" scoped>
.agreement {
  .pc {
    background: #000;
    main {
      padding-left: 1.35rem;
      padding-top: 3.23rem;
      position: relative;
      .logo {
        width: 0.38rem;
        position: absolute;
        right: 0.75rem;
        top: 3.14rem;
      }
      h4 {
        font-size: 0.4rem;
        color: #fff;
      }
      .heng {
        width: 1.16rem;
        height: 0.03rem;
        background: #00b3d6;
        margin-top: 0.23rem;
      }
      ul {
        margin-top: 0.55rem;
        padding-right: 1.46rem;
        li {
          margin-bottom: 0.55rem;
          color: #fff;
          font-size: 0.2rem;
          line-height: 0.38rem;
        }
      }
    }
  }
  .web {
    background: #000;
    main {
      padding-top: 87px;
      padding-left: 25px;
      h4 {
        font-size: 28px;
        color: #fff;
        margin-top: 58px;
        line-height: 34px;
      }
      .heng {
        width: 112px;
        height: 2px;
        background: #00b3d6;
        margin-top: 14px;
      }
      .logo {
        width: 133px;
        float: right;
        margin-right: 24px;
      }
      ul {
        margin-top: 26px;
        padding-right: 21px;
        li {
          margin-block: 26px;
          p {
            font-size: 12px;
            color: #cccccc;
          }
        }
      }
    }
    .foot {
      position: relative;
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>