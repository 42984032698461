import { render, staticRenderFns } from "./ResourceLibrary.vue?vue&type=template&id=43a51ce0&scoped=true&"
import script from "./ResourceLibrary.vue?vue&type=script&lang=js&"
export * from "./ResourceLibrary.vue?vue&type=script&lang=js&"
import style0 from "./ResourceLibrary.vue?vue&type=style&index=0&id=43a51ce0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43a51ce0",
  null
  
)

export default component.exports